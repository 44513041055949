const mq = {
  // Numerical values will result in a min-width query
  small: 576,
  medium: 768,
  large: 992,
  xLarge: 1200,
  maxLarge: 'max-width: 991px',
};

export default Object.keys(mq).reduce((accumulator, label) => {
  let prefix = typeof mq[label] === 'string' ? '' : 'min-width:';
  let suffix = typeof mq[label] === 'string' ? '' : 'px';

  accumulator[label] = `@media (${prefix + mq[label] + suffix})`;

  return accumulator;
}, {});
