import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { rhythm, scale } from '../../utils/typography';

const FooterContainer = styled('footer')`
  ...${css(scale(-1 / 5))}
  padding: ${rhythm(1)};
  line-height: ${rhythm(2)};
  text-align: center;
  span {
    margin-left: 2;
    margin-right: 2;
  }
`;

const Footer = ({ author, title }) => (
  <FooterContainer>
    <span>
      &copy; 2017 - 2018 by <a href="https://teamable.com">{author} 🎉</a>
    </span>{' '}
    <span>&middot;</span>{' '}
    <span>
      Powered by{' '}
      <a href="https://www.gatsbyjs.org" target="_blank">
        Gatsby ❤️️
      </a>
    </span>
  </FooterContainer>
);

Footer.propTypes = {
  author: PropTypes.string.isRequired,
};

export default Footer;
