import Typography from 'typography';
import Theme from 'typography-theme-alton';

const typography = new Typography(Theme);

typography.options.accentColor = '#ff5700';

Theme.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },
  a: {
    transition: 'color 200ms',
  },
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles();
}

const { rhythm, scale, options } = typography;

export { rhythm, scale, options, typography as default };
