import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import typography, { rhythm } from '../../utils/typography';
import Navigation from './Navigation';

const HeaderContainer = styled('header')`
  display: flex;
  flex-flow: row wrap;

  justify-content: space-between;
  align-items: center;

  line-height: ${rhythm(2)};
  padding: ${rhythm(1 / 2)} ${rhythm(1)};
  background: #fff;
`;

const HomeTitle = styled('h1')`
  ${css(typography.toJSON().h1)};
  flex: 1 1 0;
  margin: 0;
  margin-right: 50px;

  a {
    font-weight: 700;
    display: block;
    color: inherit;
    text-decoration: none;
  }
`;

const Title = HomeTitle.withComponent('p');

const Emoji = styled('img')`
  vertical-align: middle;
  margin: 0;
`;

const HomeLink = ({ title }) => (
  <Link to="/">
    {title}
    &nbsp;
    <Emoji
      width={32}
      heigh={32}
      alt="hammer and wrench"
      src="https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/png/1f6e0.png"
    />
  </Link>
);

const Header = ({ isHome, title, categories }) => (
  <HeaderContainer>
    {isHome ? (
      <HomeTitle>
        <HomeLink title={title} />
      </HomeTitle>
    ) : (
      <Title>
        <HomeLink title={title} />
      </Title>
    )}

    <Navigation categories={categories} />
  </HeaderContainer>
);

Header.propTypes = {
  isHome: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Header;
