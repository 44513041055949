import 'prism-themes/themes/prism-ghcolors.css';

import React from 'react';

import { injectGlobal } from 'emotion'; // eslint-disable-line
import { StaticQuery, graphql } from 'gatsby';

import SiteMetadataContext from '../../containers/SiteMetadataContext';
import Body from './Body';
import Container from './Container';
import Footer from './Footer';
import Header from './Header';

injectGlobal`
  .gatsby-highlight-code-line {
    background-color: #eaeaea;
    display: block;
    
    margin-right: -1em;
    margin-left: -1em;
    
    padding-right: 1em;
    padding-left: 0.75em;
    
    border-left: 0.25em solid black;
  }

  .gatsby-highlight {
    background-color: white;
    margin: 0.5em 0;
    padding: 1em;
    overflow: auto;
    border: 1px solid #dddddd;
  }
  
  .gatsby-highlight pre[class*="language-"] {
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: initial;
    float: left;
    min-width: 100%;
    border: none;
  }
`;

class Template extends React.Component {
  renderContent(data, { location, children }) {
    const siteMetadata = data.site.siteMetadata;
    const siteTitle = data.site.siteMetadata.title;
    const siteAuthor = data.site.siteMetadata.author;
    const categories = (data.allMarkdownRemark.group || []).map(
      ({ fieldValue }) => fieldValue
    );

    let header;

    let rootPath = `/`;

    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`;
    }

    return (
      <>
        <SiteMetadataContext.Provider value={siteMetadata}>
          <Body>
            <Header
              isHome={location.pathname === rootPath}
              title={siteTitle}
              categories={categories}
            />
            <Container>{children}</Container>
            <Footer author={siteAuthor} />
          </Body>
        </SiteMetadataContext.Provider>
      </>
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query TemplateQuery {
            site {
              siteMetadata {
                title
                author
                disqusShortname
              }
            }
            allMarkdownRemark(
              limit: 2000
              filter: { frontmatter: { draft: { ne: true } } }
            ) {
              group(field: frontmatter___category) {
                fieldValue
              }
            }
          }
        `}
        render={data => this.renderContent(data, this.props)}
      />
    );
  }
}

export default Template;
