import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import styled from '@emotion/styled';
import { css, cx } from 'emotion'; // eslint-disable-line
import capitalize from 'lodash/capitalize';
import kebabCase from 'lodash/kebabCase';

import mq from '../../styles/mq';
import { options, rhythm, scale } from '../../utils/typography';
import NavLink from './NavLink';

const NavMenuToggle = styled('button')`
  ${mq.large} {
    display: none;
  }

  -webkit-appearance: none;
  padding: 0;
  font: inherit;
  background: transparent;
  border: 0;
  outline: 0;

  position: absolute;
  top: 6px;
  right: ${rhythm(1)};

  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 11;

  span {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background-color: ${options.bodyColor};
    font-size: 0px;
    -webkit-touch-callout: none;
    user-select: none;
    transition: background-color 300ms;
  }

  span::before,
  span::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: transform 300ms;
    background-color: ${options.bodyColor};
  }

  span::before {
    transform: translateY(-250%);
  }

  span::after {
    transform: translateY(250%);
  }
`;

const Nav = styled('nav')`
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  ul {
    display: block;

    ${mq.maxLarge} {
      display: flex;
      flex-flow: column wrap;

      transition: transform 300ms;
      transform: translateX(100%);

      position: fixed;
      z-index: 10;

      background: #fafafa;

      right: 0;
      top: 0;
      height: 100vh;

      padding: ${rhythm(3)} ${rhythm(1)};

      ${css(scale(1))};
    }
    margin: 0;
    padding: 0;

    ${css(scale(-1 / 5))};
  }

  li {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-left: ${rhythm(1)};

    ${mq.maxLarge} {
      margin: 0;
    }
  }

  ${mq.maxLarge} {
    &.open ul {
      transform: translateX(0);
    }

    &.open ${NavMenuToggle} {
      position: fixed;

      span {
        background-color: transparent;
      }

      span::before {
        transform: translateY(0) rotate(45deg);
      }

      span::after {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
`;

class Navigation extends Component {
  state = { open: false };

  handleClickOutside = evt => {
    if (this.state.open) {
      this.toggle();
    }
  };

  toggle = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const { categories } = this.props;
    const { open } = this.state;

    return (
      <Nav className={cx({ open })}>
        <NavMenuToggle onClick={this.toggle}>
          <span>Menu</span>
        </NavMenuToggle>
        <ul>
          <li>
            <NavLink exact to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/posts">Posts</NavLink>
          </li>
          {categories.map(category => (
            <li key={category}>
              <NavLink to={`/categories/${kebabCase(category)}`}>
                {capitalize(category)}
              </NavLink>
            </li>
          ))}
          <li>
            <NavLink to="/tags">Tags</NavLink>
          </li>
        </ul>
      </Nav>
    );
  }
}

Navigation.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default onClickOutside(Navigation);
