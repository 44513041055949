import React from 'react';

import styled from '@emotion/styled';

import { rhythm } from '../../utils/typography';

const maxWidth = rhythm(36);

const Container = styled('main')`
  flex: 1;

  max-width: ${maxWidth};
  padding: ${rhythm(3 / 4)} ${rhythm(1)};
  margin: 0 auto;

  @media (max-width: ${maxWidth}) {
    max-width: 100%;
  }
`;

export default Container;
