import React from 'react';

import styled from '@emotion/styled';

const Body = styled('div')`
  background: #fafafa;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export default Body;
